import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  DEFAULT_LANGUAGE_ID,
  DEFAULT_LOCATION_ID,
  SEARCH_KEYWORDS_DATA_SOURCES,
  SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
} from "src/constants";

const defaultKeywordsSettings =
  SEARCH_KEYWORDS_DATA_SOURCES.reduce<Application.KeywordsSettings>(
    (acc, dataSource) => {
      acc[dataSource] = { date: "", dateUpdatedAt: "" };

      return acc;
    },
    {} as Application.KeywordsSettings,
  );

const initialState: Application.Data = {
  hardReloadInfo: { updatedAt: "" },
  sessionDate: new Date().toISOString(),
  keywordsSettings: defaultKeywordsSettings,
  versionInfo: { version: "", updatedAt: "" },
  trackerIdsWithPartialData: [],
  latestTrackerConfig: {
    locationId: DEFAULT_LOCATION_ID,
    languageId: DEFAULT_LANGUAGE_ID,
    keywordsDataSource: SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
  },
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateVersionInfo(
      state,
      action: PayloadAction<Partial<Application.VersionInfo>>,
    ): void {
      const { version, updatedAt } = action.payload;

      state.versionInfo.updatedAt = updatedAt || state.versionInfo.updatedAt;
      state.versionInfo.version = version || state.versionInfo.version;
    },
    updateKeywordToolSettings(
      state,
      action: PayloadAction<Application.KeywordsSettings>,
    ): void {
      const keywordsSettings = action.payload;

      state.keywordsSettings = { ...keywordsSettings };
    },
    updateTrackerIdsWithPartialData(
      state,
      action: PayloadAction<Application.Data["trackerIdsWithPartialData"]>,
    ): void {
      const trackerIdsWithPartialData = action.payload;

      state.trackerIdsWithPartialData = [...trackerIdsWithPartialData];
    },
    updateLatestTrackerConfig(
      state,
      action: PayloadAction<Application.Data["latestTrackerConfig"]>,
    ): void {
      const latestTrackerConfig = action.payload;

      state.latestTrackerConfig = { ...latestTrackerConfig };
    },
    updateHardReload(
      state,
      action: PayloadAction<Partial<Application.HardReloadInfo>>,
    ): void {
      const { updatedAt } = action.payload;

      state.hardReloadInfo.updatedAt =
        updatedAt || state.hardReloadInfo.updatedAt;
    },
  },
});

export const {
  updateHardReload,
  updateVersionInfo,
  updateLatestTrackerConfig,
  updateKeywordToolSettings,
  updateTrackerIdsWithPartialData,
} = applicationSlice.actions;

export default applicationSlice.reducer;

import styles from "./SearchSharePreview.module.scss";
import { ArrowDown, ArrowUp } from "src/assets/icons";
import { RawChartItem } from "src/components/charts/LineChart/types";
import { TrackerChartIcon, TrackerKeywordsDataSourcesIcon } from "src/features";
import { PreparedPieChartDataItem } from "src/components/charts/PieChartWithLabels/types";
import {
  isNumber,
  calculateTrend,
  calculatePercentage,
  calculateValueChangeStyle,
} from "src/utils";

export const renderNameColumn = ({
  trackerId,
  trackerName,
  trackersCollectionTrackerIds,
}: {
  trackerId: Tracker.Data["id"];
  trackerName: Tracker.Data["name"];
  trackersCollectionTrackerIds: Tracker.Data["id"][];
}): JSX.Element | string | null => (
  <div className={styles.trackerNameTableCell}>
    <TrackerChartIcon
      trackerId={trackerId}
      trackerIds={trackersCollectionTrackerIds}
    />
    <span className={styles.tableCellValue}>{trackerName}</span>

    <TrackerKeywordsDataSourcesIcon trackerId={trackerId} />
  </div>
);

export const renderTrendChangeColumn = (
  value: string | number,
): JSX.Element | string | null => {
  let [icon, mathSymbol]: [JSX.Element | null, string] = [null, ""];

  if (!isNumber(value)) return null;

  if (value > 0) {
    icon = <ArrowUp />;

    mathSymbol = "+";
  } else if (value < 0) {
    icon = <ArrowDown />;

    mathSymbol = "-";
  }

  const formattedValue = `${mathSymbol}${Math.abs(Math.round(value))}%`;

  return (
    <div className={styles.tableCell}>
      {icon}
      <span className={styles.tableCellValue}>{formattedValue}</span>
    </div>
  );
};

export const renderPieChartValue = (
  value: number,
  additionalValue: number = 0,
): JSX.Element | string | null => (
  <div className={styles.pieChartValue}>
    <span>{`${value}%`}</span>
    <div style={calculateValueChangeStyle(additionalValue)}>
      {renderTrendChangeColumn(additionalValue)}
    </div>
  </div>
);

export const renderPieChartLabel = (
  data: PreparedPieChartDataItem,
): JSX.Element | string | null => (
  <div className={styles.pieChartLabelWrapper}>
    <span className={styles.pieChartLabel}>{data.label}</span>
    <TrackerKeywordsDataSourcesIcon trackerId={data.id} />
  </div>
);

export const renderPieChartTooltipLabel = (
  data: PreparedPieChartDataItem,
): JSX.Element | string | null => (
  <div className={styles.pieChartTooltipLabelWrapper}>
    <span className={styles.pieChartLabel}>{data.label}</span>
    <TrackerKeywordsDataSourcesIcon trackerId={data.id} />
  </div>
);

export const formatSearchShareLineChart = ({
  trackerIds,
  lienChartData,
}: {
  trackerIds: Tracker.Data["id"][];
  lienChartData: Widgets.SearchShare.LineChart;
}): RawChartItem[] => {
  const mappedData = new Map<string, Record<Tracker.Data["id"], number>>();

  for (const trackerId in lienChartData) {
    const isTrackerActive = trackerIds.includes(trackerId);

    if (!isTrackerActive) continue;

    const trackerData = lienChartData[trackerId];

    if (!trackerData) continue;

    for (const { date, volume } of trackerData) {
      const data = mappedData.get(date);

      if (data) mappedData.set(date, { ...data, [trackerId]: volume });
      else mappedData.set(date, { [trackerId]: volume });
    }
  }

  const formattedData = new Set<RawChartItem>();

  for (const [date, values] of mappedData) {
    let totalValue = 0;

    for (const trackerId in values) {
      const value = values[trackerId];

      if (value) totalValue += value;
    }

    const formattedValues = new Map<Tracker.Data["id"], number>();

    for (const trackerId in values) {
      const value = values[trackerId];

      const formattedValue =
        value && totalValue ? (value / totalValue) * 100 : 0;

      formattedValues.set(trackerId, formattedValue);
    }

    formattedData.add({ date, values: Object.fromEntries(formattedValues) });
  }

  return [...formattedData];
};

export const formatSearchSharePieChart = ({
  endDate,
  startDate,
  trackerIds,
  pieChartData,
  lineChartData,
}: {
  lineChartData: RawChartItem[];
  trackerIds: Tracker.Data["id"][];
  pieChartData: Widgets.SearchShare.PieChart;
  endDate: Widgets.VolumeShare.Data["endDate"];
  startDate: Widgets.VolumeShare.Data["startDate"];
}): Widgets.PieChart.Item => {
  const mappedData = new Set<Widgets.PieChart.Value>();

  let totalValue = 0;

  for (const trackerId in pieChartData) {
    const isTrackerActive = trackerIds.includes(trackerId);

    if (!isTrackerActive) continue;

    const value = pieChartData[trackerId];

    if (value) totalValue += value.volume;
  }

  for (const trackerId in pieChartData) {
    const isTrackerActive = trackerIds.includes(trackerId);

    if (!isTrackerActive) continue;

    const value = pieChartData[trackerId];

    const percentage =
      value && totalValue ? (value.volume / totalValue) * 100 : 0;

    const formattedPercentage = Number(percentage.toFixed(2));

    const trendChange = calculateLineChartTrendChange(lineChartData, trackerId);

    mappedData.add({
      trackerId,
      value: formattedPercentage,
      additionalValue: trendChange,
    });
  }

  return {
    endDate,
    startDate,
    values: [...mappedData],
  };
};

export const formatSearchShareTableChart = ({
  trackers,
  pieChartData,
}: {
  pieChartData: Widgets.PieChart.Item;
  trackers: Tracker.Data[];
}): Array<
  Widgets.SearchShare.TableChartValue & Pick<Tracker.Data, "id" | "name">
> => {
  const mappedData = new Map<
    Tracker.Data["id"],
    Widgets.SearchShare.TableChartValue & Pick<Tracker.Data, "id" | "name">
  >();

  for (const { id: trackerId } of trackers) {
    const tracker = trackers.find((tracker) => tracker.id === trackerId);

    if (!tracker) continue;

    const { value: percentage = 0, additionalValue: trendChange = 0 } =
      pieChartData.values.find((tracker) => tracker.trackerId === trackerId) ||
      {};

    mappedData.set(trackerId, {
      volume: 0,
      percentage,
      trendChange,
      id: trackerId,
      name: tracker.name,
    });
  }

  return [...mappedData.values()];
};

export const calculatePieChartTotalValue = ({
  values,
}: Widgets.PieChart.Item): number =>
  values.reduce((acc, { value }) => acc + value, 0);

export const calculatePieChartTotalAdditionalValue = ({
  values,
}: Widgets.PieChart.Item): number => {
  return values.reduce(
    (acc, { value, additionalValue = 0 }) =>
      acc + (value / 100) * additionalValue,
    0,
  );
};

function calculateLineChartTrendChange(
  data: RawChartItem[],
  trackerId: Tracker.Data["id"],
): number {
  const formattedData: { date: number; value: number }[] = [];

  for (const { date, values } of data) {
    const trackerData = values[trackerId] || 0;

    try {
      const formattedDate = new Date(date).valueOf();

      formattedData.push({ value: trackerData, date: formattedDate });
    } catch (error) {
      console.error(error);
    }
  }

  const sortedFormattedData = formattedData.sort((a, b) => a.date - b.date);

  const trend = calculateTrend(sortedFormattedData, "date", "value");

  const [trendStartValue, trendEndValue] = [
    trend[0]?.value || 0,
    trend[trend.length - 1]?.value || 0,
  ];

  return calculatePercentage(trendStartValue, trendEndValue);
}

import styles from "./VolumeSharePreview.module.scss";
import { ArrowDown, ArrowUp } from "src/assets/icons";
import { RawChartItem } from "src/components/charts/LineChart/types";
import { TrackerChartIcon, TrackerKeywordsDataSourcesIcon } from "src/features";
import { PreparedPieChartDataItem } from "src/components/charts/PieChartWithLabels/types";
import {
  isNumber,
  formatToLocaleNumber,
  calculateValueChangeStyle,
} from "src/utils";

export const renderNameColumn = ({
  trackerId,
  trackerName,
  trackersCollectionTrackerIds,
}: {
  trackerId: Tracker.Data["id"];
  trackerName: Tracker.Data["name"];
  trackersCollectionTrackerIds: Tracker.Data["id"][];
}): JSX.Element | string | null => (
  <div className={styles.trackerNameTableCell}>
    <TrackerChartIcon
      trackerId={trackerId}
      trackerIds={trackersCollectionTrackerIds}
    />
    <span className={styles.tableCellValue}>{trackerName}</span>
    <TrackerKeywordsDataSourcesIcon trackerId={trackerId} />
  </div>
);

export const renderTrendChangeColumn = (
  value: string | number,
): JSX.Element | string | null => {
  let [icon, mathSymbol]: [JSX.Element | null, string] = [null, ""];

  if (!isNumber(value)) return null;

  if (value > 0) {
    icon = <ArrowUp />;

    mathSymbol = "+";
  } else if (value < 0) {
    icon = <ArrowDown />;

    mathSymbol = "-";
  }

  const formattedValue = `${mathSymbol}${Math.abs(Math.round(value))}%`;

  return (
    <div className={styles.tableCell}>
      {icon}
      <span className={styles.tableCellValue}>{formattedValue}</span>
    </div>
  );
};

export const renderPieChartValue = (
  value: number,
  additionalValue: number = 0,
): JSX.Element | string | null => (
  <div className={styles.pieChartValue}>
    <span>{formatToLocaleNumber(value)}</span>
    <div style={calculateValueChangeStyle(additionalValue)}>
      {renderTrendChangeColumn(additionalValue)}
    </div>
  </div>
);

export const renderPieChartLabel = (
  data: PreparedPieChartDataItem,
): JSX.Element | string | null => (
  <div className={styles.pieChartLabelWrapper}>
    <span className={styles.pieChartLabel}>{data.label}</span>
    <TrackerKeywordsDataSourcesIcon trackerId={data.id} />
  </div>
);

export const renderPieChartTooltipLabel = (
  data: PreparedPieChartDataItem,
): JSX.Element | string | null => (
  <div className={styles.pieChartTooltipLabelWrapper}>
    <span className={styles.pieChartLabel}>{data.label}</span>
    <TrackerKeywordsDataSourcesIcon trackerId={data.id} />
  </div>
);

export const formatVolumeShareLineChart = ({
  trackerIds,
  lineChartData,
}: {
  trackerIds: Tracker.Data["id"][];
  lineChartData: Widgets.VolumeShare.LineChart;
}): RawChartItem[] => {
  const [forecastedDates, mappedData] = [
    new Set<string>(),
    new Map<string, Record<Tracker.Data["id"], number>>(),
  ];

  for (const trackerId in lineChartData) {
    const isTrackerActive = trackerIds.includes(trackerId);

    if (!isTrackerActive) continue;

    const trackerData = lineChartData[trackerId];

    if (!trackerData) continue;

    for (const { date, volume, isForecasted } of trackerData) {
      const data = mappedData.get(date);

      if (isForecasted) forecastedDates.add(date);

      if (data) mappedData.set(date, { ...data, [trackerId]: volume });
      else mappedData.set(date, { [trackerId]: volume });
    }
  }

  const formattedData = new Set<RawChartItem>();

  for (const [date, value] of mappedData)
    formattedData.add({
      date,
      values: value,
      isForecasted: forecastedDates.has(date),
    });

  return [...formattedData];
};

export const formatVolumeSharePieChart = ({
  endDate,
  startDate,
  trackerIds,
  pieChartData,
  tableChartData,
}: {
  trackerIds: Tracker.Data["id"][];
  pieChartData: Widgets.VolumeShare.PieChart;
  endDate: Widgets.VolumeShare.Data["endDate"];
  tableChartData: Widgets.VolumeShare.TableChart;
  startDate: Widgets.VolumeShare.Data["startDate"];
}) => {
  const mappedData = new Set<Widgets.PieChart.Value>();

  for (const trackerId in pieChartData) {
    const isTrackerActive = trackerIds.includes(trackerId);

    if (!isTrackerActive) continue;

    const trackerData = pieChartData[trackerId];

    const trendChange = tableChartData[trackerId]?.trendChange;

    if (trackerData)
      mappedData.add({
        trackerId,
        value: trackerData.volume,
        additionalValue: trendChange,
      });
  }

  return {
    startDate,
    endDate,
    values: [...mappedData],
  };
};

export const formatVolumeShareTableChart = ({
  trackers,
  tableChartData,
}: {
  trackers: Tracker.Data[];
  tableChartData: Widgets.VolumeShare.TableChart;
}): Array<
  Widgets.VolumeShare.TableChartValue & Pick<Tracker.Data, "id" | "name">
> => {
  const mappedData = new Set<
    Widgets.VolumeShare.TableChartValue & Pick<Tracker.Data, "id" | "name">
  >();

  for (const trackerId in tableChartData) {
    const tracker = trackers.find((tracker) => tracker.id === trackerId);

    if (!tracker) continue;

    const trackerData = tableChartData[trackerId];

    if (trackerData)
      mappedData.add({ ...trackerData, id: trackerId, name: tracker.name });
  }

  return [...mappedData];
};

export const calculatePieChartTotalValue = ({
  values,
}: Widgets.PieChart.Item): number =>
  values.reduce((acc, { value }) => acc + value, 0);

export const calculatePieChartTotalAdditionalValue = ({
  values,
}: Widgets.PieChart.Item): number => {
  const totalVolume = values.reduce((acc, { value }) => acc + value, 0);

  const relativeTrend = values.reduce(
    (acc, { value, additionalValue = 0 }) => acc + value * additionalValue,
    0,
  );

  return relativeTrend / totalVolume;
};
